import React, { useEffect } from "react";
import Typewriter from "typewriter-effect";
import "./Landing.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Landing() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="container" style={{}}>
      <div className="row">
        <div
          className="col-lg-12"
          data-aos="fade-zoom-in"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
          style={{
            position: "absolute",
            top: "350px",
            left: " 0px",
            bottom: "0px",
          }}
        >
          <div className="banner-text">
            <h1 className="responsive-headline">
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("من امیرحسین جعفری هستم")
                    .pauseFor(2500)
                    .deleteAll();
                  typewriter
                    .typeString("به سایت من خوش آمدید")
                    .pauseFor(2500)
                    .deleteAll()
                    .start();
                }}
              />
            </h1>
            <h3>
              شغل من <span>برنامه نویس</span>,<span>طراح سایت</span> و{" "}
              <span>طراح گرافیک هستم</span> خلق کردن یکی از مهم ترین دلیل هایی
              هستش که تا الان تونستم از دوره های تاریک زندگیم بتونم عبور کنم
            </h3>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Header from "../../Components/Header/Header";
import "./Index.css";
import About from "../../Components/About/About";
import Resume from "../../Components/Resume/Resume";
import Portfolio from "../../Components/Portfolio/Portfolio";
import Footer from "../../Components/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Index() {
  return (
    <>
      <Header />
      <About />
      <Resume />
      <Portfolio />
      <Footer />
    </>
  );
}

import React, { useEffect, useState } from "react";
import "./Portfolio.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Portfolio() {
  const [products, setProducts] = useState([]);
  const [productImg, setproductImg] = useState([]);
  useEffect(() => {
    fetch("http://localhost:3000/portofolio")
      .then((res) => res.json())
      .then((allData) => {
        setProducts(allData);
      });
  }, []);
  return (
    <div className="container">
      <section id="portfolio">
        <h2 className="Portofolio-Title">نمونه کارها</h2>
        <div class="row">
          {products.map((product, index) => (
            <div className="col-lg-6" key={index}>
              <div class="twelve columns collapsed">
                <div
                  id="portfolio-wrapper"
                  class="bgrid-quarters s-bgrid-thirds cf"
                >
                  <div class="columns portfolio-item">
                    <div class="item-wrap">
                      <Link to={`portofolio/${product.id}`} title="">
                        <img alt="" src={product.img} />
                        <div class="overlay">
                          <div class="portfolio-item-meta">
                            <svg
                              className="magnify"
                              xmlns="http://www.w3.org/2000/svg"
                              height="2.5em"
                              viewBox="0 0 512 512"
                            >
                              <style></style>
                              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                            </svg>
                            <svg
                              className="magnify2"
                              xmlns="http://www.w3.org/2000/svg"
                              height="2.5em"
                              viewBox="0 0 640 512"
                            >
                              <path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
                            </svg>
                          </div>
                        </div>
                        <div class="link-icon">
                          <i class="icon-plus"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";

export default function PortofolioDetail() {
  const [portfolio, setPortofolio] = useState([]);
  const [techs, settechs] = useState([]);
  const { PortoFolioName } = useParams();

  useEffect(() => {
    fetch(`http://localhost:3000/portofolio/${PortoFolioName}`)
      .then((res) => res.json())
      .then((result) => {
        setPortofolio(result);
        settechs(result.techs);
      });
  }, []);
  return (
    <>
      <Header sliderStatus={false} />
      <section>
        <div className="container">
          <div className="row">
            <div className="portofolio-box">
              <h1>{portfolio.title}</h1>
              <hr></hr>
              <img alt="" src={`/${portfolio.img}`} />
              <p>{portfolio.body}</p>
     
              {techs.map((tech) => (
             <>
             <a href="d">{tech.title} </a>
             </>
              ))}
              <span> تاریخ انجام پروژه : {portfolio.date} </span>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

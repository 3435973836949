import React from "react";
import "./About.css";
import "aos/dist/aos.css";
import resumeFile from "./resume.pdf";

export default function About() {
  const downloadfile = () => {
    alert("s");
  };
  return (
    <section id="about">
      <div
        className=""
        data-aos="fade-zoom-in"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
      

        <div className="nine columns main-col">
          <h2>درباره من</h2>
          <div className="hrparent">
            <hr/>
          </div>

          <p>
            اما برای اینکه ببینید این همه خطای مولود کسانی که لذت را متهم می
            کنند و درد را می ستایند از کجا آمده است، تمام موضوع را باز می کنم و
            همان چیزهایی را که توسط آن کاشف حقیقت گفته شده و به قول معمار یک
            شادی گفته است، توضیح می دهم. زندگی زیرا هیچ‌کس لذت را تحقیر نمی‌کند،
            متنفر نمی‌کند یا از آن فرار نمی‌کند، بلکه به خاطر آن است که دردهای
            بزرگ برای کسانی است که نمی‌دانند چگونه لذت را با عقل دنبال کنند.
            علاوه بر این، کسی نیست که چون درد را دوست دارد، دنبال آن باشد و
            بخواهد آن را به دست آورد، بلکه به این دلیل که چنین مواقعی هرگز پیش
            نمی‌آید که از طریق کار و درد به دنبال لذتی بزرگ باشد. برای رسیدن به
            کوچکترین جزئیات، هر یک از ما هر تمرینی را برای بدن انجام می دهیم که
            پر زحمت باشد.
          </p>

          <div className="row">
          
   

            <div className="columns download">
              <p>
                <a
                  onClick={downloadfile}
                  href={resumeFile}
                  download="Example-PDF-document"
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                >
                  <i className="fa fa-download"></i>دانلود رزومه
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
